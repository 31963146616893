import React, { useEffect } from 'react';
import lottie from 'lottie-web';

const LottieLoader = () => {
  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: document.querySelector("#lottie-animation"),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'feather.json'
    });

    // Return a cleanup function to run when the component unmounts or re-renders
    return () => {
      animation.destroy(); // This will clear the Lottie animation
    };
  }, []); // Empty dependency array means this useEffect runs once when component mounts, and cleanup runs on unmount

  return (
    <div 
      id="lottie-animation" 
      style={{
        width: 400, 
        height: 400, 
        position: 'absolute',
        top: '35%',
        left: '50%',
        transform: 'translate(-50%, -60%)'
      }}
    ></div>
  );
};

export default LottieLoader;
