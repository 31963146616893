import React from 'react';
import './footer.css';


const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <h1>Syntax-GPT</h1>
                <div className="links">
                    <a href="/contact-us">Contact Us</a> 
                    <a href="/terms-and-conditions">Terms and Conditions</a> 
                    <a href="/privacy-policy">Privacy Policy</a> 
                    <a href="/cancellation-policy"> Cancellation Policy</a> 
                    <p>&copy; 2023 Syntax-GPT</p>
                </div>
            </div>
        </footer>
    );
}


export default Footer;
