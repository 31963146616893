import React from 'react';
import './contact.css';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/footer';


const ContactUs = () => {
    const navigate = useNavigate();

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_wvsi59v', 'template_d27ddej', e.target, 'r0HiJwbfDU400YBX-')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        navigate.push('/');
    }

    return (
        <div>
            
            <section className="contact-section">
                <div className="form-container">
                    <h1 className="contact-title">Contact Us</h1>
                    <form className="contact-form" onSubmit={sendEmail}>
                        <label>Name</label>
                        <input type="text" name="name" />
                        <label>Email</label>
                        <input type="email" name="email" />
                        <label>Phone Number</label>
                        <input type="text" name="phone" />
                        <label>Message</label>
                        <textarea name="message" />
                        <input type="submit" value="Send" className="submit-button" />
                    </form>
                </div>
            </section>
            <Footer />
        </div>
    );
    
}

export default ContactUs;
