import React, { useState, useEffect, useRef } from 'react';
import audioFile from '../om.mp3';
import '../style.css';
import { OpenAIApi, Configuration } from 'openai-edge';
import Popup from './Popup';
import Footer from './footer';
import LottieLoader from './LottieLoader';

const Chat = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);
    const videoRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isBotTalking, setIsBotTalking] = useState(false);

    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    const config = new Configuration({
        apiKey: apiKey
    });
    const openai = new OpenAIApi(config);

    useEffect(() => {
        const timer = setTimeout(() => {
            window.location.href = "https://cosmofeed.com/vp/65193da9d74756001d25c10e";
        }, 600000); // 10 minutes
        return () => clearTimeout(timer); 
    }, []);

    useEffect(() => {
        if (isBotTalking && videoRef.current) {
            videoRef.current.src = require(`../talking${Math.floor(Math.random() * 2) + 1}.mp4`);
            videoRef.current.play();
        } else if (videoRef.current) {
            videoRef.current.src = require('../blinking.mp4');
            videoRef.current.play();
        }
    }, [isBotTalking]);

    async function getBotResponse(prompt) {
        try {
            const customPrompt = "Act as you are in an imaginary world and you are full of knowledge! So before answering any question you are bound to have some rules which you must follow! Whenever you answer a question you need to start with \"as a messenger of Lord Krishna I Would say\" and answer the question. Nothing more or less than that. " + prompt;
            const response = await openai.createChatCompletion({
                model: 'gpt-3.5-turbo',
                messages: [
                    {
                        role: 'system',
                        content: "You are a helpful assistant."
                    },
                    {
                        role: 'user',
                        content: customPrompt
                    }
                ]
            });
            const responseBody = await response.json();
            
            // Check if the responseBody has choices and the first choice has a message content
            if (responseBody.choices && responseBody.choices[0] && responseBody.choices[0].message && responseBody.choices[0].message.content) {
                return responseBody.choices[0].message.content.trim();
            } else {
                console.error('Unexpected response body:', responseBody);
                return 'I am sorry, but I was unable to understand your request.';
            }
        } catch (error) {
            console.error('OpenAI Error:', error);
            return `An error has occurred: ${error.message}`;
        }
    }
    
    

    async function playSpeechFromElevenLabsAPI(text) {
        try {
            const voiceId = process.env.REACT_APP_VOICE_ID;
            const xiApiKey = process.env.REACT_APP_XI_API_KEY;
            const response = await fetch(`https://api.elevenlabs.io/v1/text-to-speech/${voiceId}/stream`, {
                method: 'POST',
                headers: {
                    'xi-api-key': xiApiKey,
                    'Content-Type': 'application/json',
                    'accept': 'audio/mpeg'
                },
                body: JSON.stringify({
                    "text": text,
                    "model_id": "eleven_monolingual_v1",
                    "voice_settings": {
                        "stability": 0.05,
                        "similarity_boost": 0.05
                    }
                })
            });
    
            const data = await response.arrayBuffer();
            const blob = new Blob([data], { type: 'audio/mpeg' });
            const audioUrl = URL.createObjectURL(blob);
            
            setIsBotTalking(true);
            const audio = new Audio(audioUrl);
            audio.onended = () => setIsBotTalking(false); // Reset to idle video after audio ends
            audio.play();
        } catch (error) {
            console.error(`Eleven Labs API Error: ${error}`);
        }
    }
    

    const handleSend = async () => {
        if (input.trim() !== '') {
            setIsLoading(false);
            setMessages([...messages, { text: input, sender: 'user' }]);
            setInput('');

            

            let botResponse = await getBotResponse(input);
            await playSpeechFromElevenLabsAPI(botResponse);
            setMessages([...messages, { text: input, sender: 'user' }, { text: botResponse, sender: 'bot' }]);
            
        }
    };

    const handleAudioToggle = () => {
        setIsPlaying(!isPlaying);
        if (audioRef.current) {
            audioRef.current.volume = 0.7; // Set volume to 70%
            isPlaying ? audioRef.current.pause() : audioRef.current.play();
        }
    };
    

    const [isModalVisible, setModalVisible] = useState(false);

    
    


    return (
        <div className="App">
            {isLoading && <LottieLoader />}
            {isModalVisible && <Popup onClose={() => setModalVisible(false)} />}
            
            {/* <video ref={videoRef} className="avatar" autoPlay muted loop /> */}
    
            <div className="messages-container">
                <div id="messages">
                    {messages.map((message, index) => (
                        <div key={index} className={message.sender}>
                            {message.text}
                            {message.sender === 'bot' && (
                                <>
                                    <br />
                                    <br />
                                    <span>
                                        <a 
                                            href="#" 
                                            className="donate-link" 
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setModalVisible(true);
                                            }}
                                        >
                                            Donate to our cause
                                        </a>
                                        <span> to keep this website running.</span>
                                    </span>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
    
            <div className="input-container">
                <div className="button-container">
                    <button className='click' onClick={handleAudioToggle}>
                        Click here and ask questions!
                    </button>
                </div>
                <div className="message-submit-container">
                    <input
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        placeholder="Type a message..."
                        onKeyDown={(e) => { if (e.key === 'Enter') handleSend(); }}
                        className="message-input"
                    />
                    <button onClick={handleSend} className="submitt-button">
                        <img src={require('../submit.png')} alt="submit" className="button-icon" />
                    </button>
                </div>
            </div>
    
            <audio ref={audioRef} src={audioFile} loop />
            <Footer />
        </div>
    );
    
    
    
};



export default Chat;







