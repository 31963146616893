import React from 'react';
import '../components/terms.css';
import Footer from '../components/footer';


const Terms = () => {
    return (
         <div>
                    <div className="containerT">
                    
                    <h1>Terms and Conditions</h1>
                        <p>Effective Date: [30th June 2023]</p>

                        <p>This Agreement governs the terms under which you may access and use the AI-Powered Learning Path Creator website and services (collectively referred to as the "Service"), provided by Syntax Sarcasm ("we", "us", or "our").
                            By accessing or using the Service, you ("User", "you") agree to be bound by these Terms and Conditions.. 
                            aAcceptance of Termsaa
                            1.By using our Service, you affirm that you are at least 18 years of age and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms, and to abide by and comply with these Terms.a
                            2. Registration
                            To use our Service, you must register for an account. You agree to provide true, accurate, current, and complete information during the registration process and to update such information to keep it accurate and current.a
                            3. Payment Terms
                            The Service costs 50-150 rupees per use, payable at the point of purchase. Payments are non-refundable and non-transferable. You are responsible for all applicable taxes.a
                            4. Use of Service
                            Our Service offers personalized learning paths created through AI analysis. You may use the Service for your personal, non-commercial use. You agree to provide truthful and accurate information for the Service to function correctly.a
                            5. Intellectual Property
                            All content, design, graphics, compilation, magnetic translation, digital conversion, and other matters related to the Site are protected under applicable copyrights, trademarks, and other proprietary rights. You may not use, copy, distribute, display, sell, or exploit any content or data contained on the Site without our prior written consent.a
                            6. User Conduct
                            You agree not to use the Service for any unlawful purpose or any purpose prohibited under this clause. You agree not to use the Service in any way that could damage the Site, Services, or general business of Syntax Sarcasm.a
                            7. Privacy
                            Your use of the Service is also governed by our Privacy Policy. Please review our Privacy Policy to understand our practices.a
                            8. Disclaimer of Warranty
                            The service is provided on an “as is” and “as available” basis without any warranties of any kind. We disclaim all warranties, including, but not limited to, the warranty of title, merchantability, non-infringement of third partie's rights, and fitness for particular purpose.a
                            9. Limitation of Liability
                            In no event will we be liable for any damages (including, without limitation, direct, indirect, incidental, special, consequential, or exemplary damages, damages arising from personal injury/wrongful death, and damages resulting from lost data or business interruption) arising out of or relating to your use or inability to use the Service.a
                            10. Amendments
                            We reserve the right to modify these Terms and Conditions at any time. Your continued use of the Service following any change constitutes your agreement to follow and be bound by the Terms as changed.a
                            11. Governing Law
                            These Terms and Conditions are governed by and construed in accordance with the laws of India. Any disputes or claims relating to the use of the Service shall be resolved by Indian courts.a
                            12. Contact Information
                            If you have any questions about these Terms and Conditions, please contact us at Syntaxsarcasm5544@gmail.com .
                            a Please note that by using our Service, you confirm that you have read and understood these Terms and Conditions and agree to abide by them.</p>

                            
                            
                </div>

                <Footer />

        </div>
        
        
    );
}

export default Terms;
