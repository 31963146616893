import React from 'react';
import '../components/privacy.css';
import Footer from '../components/footer';


const privacy = () => (
    <div>
       
            <div className="privacyP">
            <h1>Privacy Policy</h1>
            <p>Effective Date: 30th June 2023</p>
            <p>This Privacy Policy describes how we syntaxsarcasm, as a provider of the AI-Powered Learning Path Creator service (the "Service"), collect, use, and share information when you access and use our Service.</p>
            <h2>1. Information We Collect</h2>
            <ul>
                <li>Personal Identifiable Information (PII): We collect PII such as your name, email address, and payment details when you register for our Service. This information is necessary for us to provide you with our services and to manage your user account.</li>
                <li>User-generated Information: When using our service, you will be asked to provide information through a questionnaire to help us create a personalized learning path for you. This includes your skills, goals, interests, and weaknesses.</li>
                <li>Usage Information: We automatically collect information on how you interact with our Service, like the pages or content you view, the searches you conduct, and the dates and times of your visits.</li>
            </ul>
            <p>2. How We Use Your Information</p>
            <p>We use the information we collect to:</p>
            <ul>
                <li>Provide, personalize, and improve our Service.</li>
                <li>Generate your personalized learning path.</li>
                <li>Process transactions and send you related information including confirmations and receipts.</li>
                <li>Send you technical notices, updates, and support messages.</li>
                <li>Respond to your comments, questions, and requests, and provide customer service.</li>
                <li>Monitor and analyze trends, usage, and activities in connection with our Service.</li>
            </ul>
            <p>3. Sharing of Information</p>
            <p>We do not share your personal information except in the following cases:</p>
            <ul>
                <li>With your consent or at your direction.</li>
                <li>We may share aggregated or de-identified Information, which cannot reasonably be used to identify you.</li>
            </ul>
            <p>4. Security</p>
            <p>We take reasonable measures to protect your personal information from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction. We also require that you maintain the security of your account by not sharing your password with others and restricting access to your account and your computer.</p>
            <p>5. International Data Transfers</p>
            <p>Our Service is available worldwide, but your information may be stored and processed in any country where we have operations or where we engage service providers. If you are located in the European Union or other regions with laws governing data collection and use, you acknowledge that we may transfer information to countries outside of your country of residence, which may have data protection rules that are different from those of your country.</p>
            <p>6. Changes to This Privacy Policy</p>
            <p>We may update this Privacy Policy from time to time. If we make any changes, we will notify you by revising the 'Effective Date' at the top of this policy and, in some cases, we may provide you with additional notice.</p>
            <p>7. Contact Us</p>
            <p>If you have any questions about this Privacy Policy, please contact us at syntaxsarcasm5544@gmail.com.</p>
            <p>Please note that by using our Service, you confirm that you have read and understood this Privacy Policy and agree to its terms.</p>
            </div>
            <Footer />
    </div>
    
);

export default privacy;
