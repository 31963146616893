import React from 'react';
import './Popup.css';
import QRImage from '../QR.jpg';

const Popup = ({ onClose }) => {
    return (
        <div className="popup-container">
            <div className="popup-content">
                <button className="close-button" onClick={onClose}>X</button>
                <h2 className='donate'>Donate to SyntaxGpt</h2>
                <p>Please contribute to this project by making a donation. Every small donation counts. Love, Krishna ❤️</p>
                <img src={QRImage} alt="QR Code" />
                <h2>UPI ID: s.mishra2199@axl</h2>
            </div>
        </div>
    );
};

export default Popup;
