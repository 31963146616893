import React from 'react';
import '../components/CancellationPolicy.css';
import Footer from '../components/footer';


const CancellationPolicy = () => (
    <div>
       
            <div className="Cancellation">
            <h1>At SYNTAX SARCASM,</h1>
            <p>Effective Date: 30th June 2023</p>

             <p>we take great pride in offering our cutting-edge product, Syntax-GPT, which facilitates AI chat and voice conversations. Our commitment to providing top-notch services relies heavily on the continuous operation, maintenance, and support of our application. We understand that circumstances may sometimes lead to the need for cancellation, and we want to ensure our users are fully informed about our cancellation policy.</p>

                <h2>Cancellation Policy Overview:</h2>

                <h3>Non-Refundable Nature:</h3> 
                <p>We regret to inform you that, as of the moment you start using the Syntax-GPT application, all payments made are non-refundable. This policy has been put in place due to the significant costs incurred by SYNTAX SARCASM for the operation and upkeep of our services. These costs include server expenses, API usage fees, as well as the compensation for the skilled professionals who continuously work to enhance and maintain our application's functionality.</p>

                <h2>Service Continuity:</h2>
                <p>Our commitment to offering high-quality AI chat and voice conversation capabilities means that we maintain a robust infrastructure comprising powerful servers and a dedicated team of experts. Your subscription contributes directly to sustaining this infrastructure and ensuring a seamless user experience.</p> 

                <h2>No Partial Refunds:</h2>
                <p>We do not offer partial refunds for unused portions of your subscription. Once you have subscribed and made a payment, the full amount is committed to maintaining the application's performance, and we are unable to process any form of reimbursement.</p> 

                <h2>Billing and Cancellation Process:</h2> 
                <p>Billing for our services is typically done on a monthly or yearly basis, depending on your subscription choice. Should you decide to cancel, you will not be charged for subsequent billing cycles. Please remember that cancellations are effective immediately upon the request and do not retroactively refund previous payments.</p>

                <h2>Contact Us:</h2>

                <p>We understand that circumstances may vary, and exceptions may arise. If you have any concerns or questions regarding our cancellation policy, please don't hesitate to reach out to our customer support team at [syntaxsarcasm5544@gmail.com]. We are here to assist you and address any inquiries or issues you may encounter.</p>

                <h2>In conclusion,</h2> 
                <p>SYNTAX SARCASM remains dedicated to delivering the best AI chat and voice conversation services through our Syntax-GPT application. Your support and commitment allow us to maintain this high standard of service. We appreciate your understanding of our cancellation policy, which ensures the continued development and enhancement of our product for all users. Thank you for choosing SYNTAX SARCASM as your AI conversation partner.</p>
            </div>
            <Footer />
    </div>
    
);

export default CancellationPolicy;
